"use strict";
/* tslint:disable */
// This file was automatically generated and should not be edited.
Object.defineProperty(exports, "__esModule", { value: true });
//==============================================================
// START Enums and Input Objects
//==============================================================
var EmailIntentionEnum;
(function (EmailIntentionEnum) {
    EmailIntentionEnum["phishing"] = "phishing";
})(EmailIntentionEnum = exports.EmailIntentionEnum || (exports.EmailIntentionEnum = {}));
var FeatureEnum;
(function (FeatureEnum) {
    FeatureEnum["analytics"] = "analytics";
    FeatureEnum["billing"] = "billing";
    FeatureEnum["devices"] = "devices";
    FeatureEnum["feed"] = "feed";
    FeatureEnum["integrations"] = "integrations";
    FeatureEnum["message_admin"] = "message_admin";
})(FeatureEnum = exports.FeatureEnum || (exports.FeatureEnum = {}));
var IntervalEnum;
(function (IntervalEnum) {
    IntervalEnum["DAY"] = "DAY";
    IntervalEnum["MONTH"] = "MONTH";
    IntervalEnum["WEEK"] = "WEEK";
    IntervalEnum["YEAR"] = "YEAR";
})(IntervalEnum = exports.IntervalEnum || (exports.IntervalEnum = {}));
var ProductNameEnum;
(function (ProductNameEnum) {
    ProductNameEnum["BASIC"] = "BASIC";
    ProductNameEnum["ENTERPRISE"] = "ENTERPRISE";
    ProductNameEnum["PRO"] = "PRO";
    ProductNameEnum["TRIAL"] = "TRIAL";
})(ProductNameEnum = exports.ProductNameEnum || (exports.ProductNameEnum = {}));
var SubscriptionStatusEnum;
(function (SubscriptionStatusEnum) {
    SubscriptionStatusEnum["active"] = "active";
    SubscriptionStatusEnum["cancelled"] = "cancelled";
    SubscriptionStatusEnum["expired"] = "expired";
    SubscriptionStatusEnum["past_due"] = "past_due";
    SubscriptionStatusEnum["pending"] = "pending";
})(SubscriptionStatusEnum = exports.SubscriptionStatusEnum || (exports.SubscriptionStatusEnum = {}));
var WebNoticeEnum;
(function (WebNoticeEnum) {
    WebNoticeEnum["no_plan"] = "no_plan";
    WebNoticeEnum["past_due"] = "past_due";
    WebNoticeEnum["subscription_end"] = "subscription_end";
    WebNoticeEnum["trial"] = "trial";
})(WebNoticeEnum = exports.WebNoticeEnum || (exports.WebNoticeEnum = {}));
//==============================================================
// END Enums and Input Objects
//==============================================================
