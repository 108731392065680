"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.batchActions = function (actions) {
    return {
        type: "BATCH_ACTION",
        actions: actions,
    };
};
// redux middleware to support batch actions
// https://github.com/reduxjs/redux/issues/911#issuecomment-149361073
exports.enableBatching = function (reducer) {
    return function batchingReducer(state, action) {
        switch (action.type) {
            case "BATCH_ACTION":
                return action.actions.reduce(batchingReducer, state);
            default:
                return reducer(state, action);
        }
    };
};
