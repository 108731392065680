"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SnipedRoutes;
(function (SnipedRoutes) {
    SnipedRoutes["HOME"] = "/";
    SnipedRoutes["ANALYTICS"] = "/analytics";
    SnipedRoutes["PHISHING"] = "/phishing";
    SnipedRoutes["LOGIN"] = "/login";
    SnipedRoutes["MESSAGES"] = "/messages";
    SnipedRoutes["BILLING"] = "/billing";
    SnipedRoutes["BILLING_ADDRESS_UPDATE"] = "/billing/address/edit";
    SnipedRoutes["BILLING_DETAILS_UPDATE"] = "/billing/details/edit";
    SnipedRoutes["BILLING_CANCEL"] = "/billing/cancel";
    SnipedRoutes["RESET_SNIPES"] = "/snipes/reset";
    SnipedRoutes["INTEGRATIONS"] = "/integrations";
    SnipedRoutes["DEVICES"] = "/devices";
    SnipedRoutes["NEW_WEBHOOK"] = "/integrations/new/webhook";
    SnipedRoutes["EDIT_WEBHOOK"] = "/integrations/webhook/edit";
    SnipedRoutes["AUTH_CALLBACK"] = "/auth/callback";
    SnipedRoutes["AUTH_ERROR"] = "/auth/error";
})(SnipedRoutes || (SnipedRoutes = {}));
exports.editWebhookRoute = function (webhookId) {
    return "/integrations/webhook/edit?id=" + webhookId;
};
exports.default = SnipedRoutes;
