"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
/* Actions */
var AuthActionType;
(function (AuthActionType) {
    AuthActionType["AUTH_SUCCESS"] = "AUTH_SUCCESS";
    AuthActionType["AUTH_REFRESH"] = "AUTH_REFRESH";
    AuthActionType["SIGN_OUT"] = "AUTH_SIGN_OUT";
})(AuthActionType = exports.AuthActionType || (exports.AuthActionType = {}));
exports.hasPlanFeature = function (planFeature, auth) {
    var planFeatures = auth && auth.plan_features || [];
    return planFeatures.indexOf(planFeature) >= 0;
};
exports.hasRoleFeature = function (roleFeature, auth) {
    var roleFeatures = auth && auth.role_features || [];
    return roleFeatures.indexOf(roleFeature) >= 0;
};
var initialState = {};
exports.authReducer = function (oldState, action) {
    if (oldState === void 0) { oldState = initialState; }
    switch (action.type) {
        case AuthActionType.AUTH_SUCCESS:
            return __assign({}, oldState, { authHash: action.payload, authError: undefined });
        case AuthActionType.SIGN_OUT:
            return __assign({}, oldState, { authHash: undefined, authError: action.error });
        case AuthActionType.AUTH_REFRESH: {
            var newAuthHash = oldState.authHash && __assign({}, oldState.authHash, { role_features: action.roleFeatures, plan_features: action.planFeatures });
            return __assign({}, oldState, { authHash: newAuthHash, authError: undefined });
        }
        default:
            return oldState;
    }
};
