"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var globalTypes_1 = require("../generated/graphql/globalTypes");
exports.Basic = {
    id: "basic",
    name: "Sniped Basic",
    price: "$0",
    monthly: true,
    features: [
        "Up to 30 users",
        "Snipes & Leaderboard",
        "Basic web interface",
        "Basic support",
    ],
    productType: globalTypes_1.ProductNameEnum.BASIC,
    selfServicePlanName: "Free Tier Up To 30 users"
};
exports.Pro = {
    id: "pro",
    name: "Sniped Pro",
    price: "$49",
    monthly: true,
    features: [
        "Up to 99 users",
        "Snipes & leaderboard",
        "Basic web interface",
        "Priority support",
    ],
    productType: globalTypes_1.ProductNameEnum.PRO,
    selfServicePlanName: "Self-Service Tier Up To 99 Users"
};
exports.Enterprise = {
    id: "enterprise",
    name: "Sniped Enterprise",
    price: "Contact us",
    monthly: false,
    features: [
        "Unlimited users",
        "Webhook integration",
        "Security analytics",
        "Dedicated support",
    ],
    productType: globalTypes_1.ProductNameEnum.ENTERPRISE,
};
exports.selfServicePlanFromProduct = function (productName) {
    switch (productName) {
        case globalTypes_1.ProductNameEnum.BASIC:
            return exports.Basic.selfServicePlanName;
        case globalTypes_1.ProductNameEnum.PRO:
            return exports.Pro.selfServicePlanName;
        default:
            return;
    }
};
